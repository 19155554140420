import {
  AutoForm,
  AutoFormAutocompleteField,
  AutoFormInputField,
  Button,
  useAutoFormControls
} from "@9amhealth/shared";
import { NineHeading } from "@9amhealth/wcl/generated/react";
import { useBlocNext } from "@blac/react";
import styled from "@emotion/styled";
import React from "react";
import { Item } from "react-stately";
import translate from "src/lib/translate";
import Translate from "src/ui/components/Translate/Translate";
import MedicalInsuranceFormBloc, {
  availableOwnerOptions,
  schemaCardDetails
} from "./MedicalInsuranceFormBloc";

const FormFieldsWrapper = styled.div`
  padding: 0 0.6rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MedicalInsuranceCardDetailsForm = () => {
  const [
    { initialCardFormValues, availablePlanNameOptions },
    { defineCardDetailsFormControls, handleFormSubmitCardDetails }
  ] = useBlocNext(MedicalInsuranceFormBloc);

  const autoFormCardDetails = useAutoFormControls({
    schema: schemaCardDetails,
    initialValue: initialCardFormValues,
    onSubmit: handleFormSubmitCardDetails
  });
  defineCardDetailsFormControls(autoFormCardDetails);

  return (
    <section>
      <NineHeading>
        <h3 className="as-h4-large">
          <Translate msg="insurance.medical.enterDetails.title" />
        </h3>
        <nine-spacer s="md"></nine-spacer>
        <p className="m0 color-c-80">
          <Translate msg="insurance.medical.enterDetails.description" />
        </p>
      </NineHeading>
      <nine-spacer s="lg"></nine-spacer>

      <AutoForm {...autoFormCardDetails.props}>
        <FormFieldsWrapper>
          <AutoFormInputField
            name="cardholderId"
            label={translate("form.label_medicalInsuranceCardholderId")}
            isRequired
          />
          <AutoFormInputField
            name="group"
            label={translate("form.label_medicalInsuranceGroup")}
            isRequired
          />
          <AutoFormAutocompleteField
            defaultItems={availableOwnerOptions}
            name="planOwner"
            label={translate("form.label_medicalInsurancePlanOwner")}
            isRequired
          >
            {(item) => <Item key={item.id}>{item.label}</Item>}
          </AutoFormAutocompleteField>

          <AutoFormAutocompleteField
            defaultItems={availablePlanNameOptions}
            name="planName"
            label={translate("form.label_medicalInsuranceHmoOrPpo")}
            isRequired
          >
            {(item) => <Item key={item.id}>{item.label}</Item>}
          </AutoFormAutocompleteField>

          <div style={{ justifyContent: "center", display: "flex" }}>
            <Button type="submit">
              <Translate msg="continue" />
            </Button>
          </div>
        </FormFieldsWrapper>
      </AutoForm>
    </section>
  );
};

export default MedicalInsuranceCardDetailsForm;
